import { godCollection } from '@/constants/godList'

import pickByPropability from '@/utils/pickByPropability'

import godList from './../constants/godList'

export default () => {
  const godId = pickByPropability(godList).id

  return godCollection[godId]
}
