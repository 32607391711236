import statBaseValue from '@/constants/statBaseValue'
import { ALIGNMENT_N, aligmentCollection } from '@/constants/aligmentList'
import { creatureTypeCollection } from '@/constants/creatureTypeList'
import { gearCollection } from '@/constants/gearList'
import { GENDER_FEMALE, GENDER_MALE, GENDER_MIDDLE } from '@/constants/genderList'
import { languageCollection } from '@/constants/languageList'
import { PARAM_CHA, PARAM_INT, PARAM_WIT } from '@/constants/paramList'
import { pcRaceCollection } from '@/constants/pcRaceList'

import calcStatBonus from '@/utils/calcStatBonus'
import checkIfAligmentPossible from '@/utils/checkIfAligmentPossible'
import generateRandomGender from '@/utils/nameGeneration/generateRandomGender'
import generateStats from '@/utils/generateStats'
import pickByPropability from '@/utils/pickByPropability'
import upLetter from '@/utils/upLetter'

import {generateFullName} from '@/components/NameGenerator'

import aligmentList from './constants/aligmentList'
import bizzareList from './constants/bizzareList'
import bondList from './constants/bondList'
import charBadList from './constants/charBadList'
import charGoodList from './constants/charGoodList'
import communicationList from './constants/communicationList'
import creatorList from './constants/creatorList'
import creatureTypeList from './constants/creatureTypeList'
import goalList from './constants/goalList'
import idealList from './constants/idealList'
import interactionTypeList from './constants/interactionTypeList'
import itemTypeList from './constants/itemTypeList'
import languageList, { withoutCommonLanguageList } from './constants/languageList'
import lesserAbilityList from './constants/lesserAbilityList'
import mannerList from './constants/mannerList'
import pcRaceList from './constants/pcRaceList'
import senseTypeList from './constants/senseTypeList'
import storyList from './constants/storyList'
import weaknessList from './constants/weaknessList'

import generateName from './utils/generateName'
import getRandomGod from './utils/getRandomGod'
import getRandomKind from './utils/getRandomKind'
import itemTransformText from './utils/itemTransformText'

const MAX_LANG_BASE = 2
const ADDITIONAL_LANG_PROBABILITY_BASE = 0.7

export default class SentientItem {
  constructor () {
    this.stats = {
      [PARAM_INT]: generateStats(),
      [PARAM_WIT]: generateStats(),
      [PARAM_CHA]: generateStats(),
    }

    this.char = {
      bad: pickByPropability(charBadList).description,
      good: pickByPropability(charGoodList).description,
    }

    this.genderId = generateRandomGender()
    this.name = generateName(this.genderId)
    this.gearId = pickByPropability(itemTypeList).id
    this.alignmentId = pickByPropability(aligmentList).id
    this.interactions = this.generateInteractions()
    this.goal = this.generateGoal()
    this.senses = pickByPropability(senseTypeList).description
    this.manner = this.generateManner()
    this.communication = pickByPropability(communicationList).description
    this.ideal = this.generateIdeal()
    this.bond = pickByPropability(bondList).description
    this.weakness = pickByPropability(weaknessList).description
    this.creator = pickByPropability(creatorList)
    this.story = this.generateStory()
    this.lesserAbility = this.generateLesserAbility()
    this.bizzare = pickByPropability(bizzareList)
    this.description = this.generateDescription()
  }

  generateIdeal = () => {
    const idealListFiltered = idealList.filter(({alignmentId}) => checkIfAligmentPossible(this.alignmentId, alignmentId))

    return pickByPropability(idealListFiltered).description
  }

  generateInteractions = () => {
    const { description, isAbleToSpeak } = pickByPropability(interactionTypeList)

    if (isAbleToSpeak) {
      const langIdList = this.generateLanguageList()
      const langStr = langIdList
        .map(
          id => languageCollection[id].nameByCase.nominative
        )
        .join('*, *')

      return description.replace(
        'одном или нескольких языках',
        `языках: *${langStr}*`
      )
    }

    return description
  }

  generateLanguageList = () => {
    const int = this.stats[PARAM_INT]
    const intBonus = calcStatBonus(int)
    const generatedLanguageList = []
    const maxLangCount = Math.max(intBonus * MAX_LANG_BASE, 1)
    let addMoreLang = true

    for (let i = 1; i <= maxLangCount && addMoreLang; i++) {
      const additionalLangProbability = ((int + intBonus) / statBaseValue) * ADDITIONAL_LANG_PROBABILITY_BASE / generatedLanguageList.length
      const pickedLangId = pickByPropability(languageList).id

      if (generatedLanguageList.includes(pickedLangId)) {
        i--
      } else {
        generatedLanguageList.push(pickedLangId)
      }

      if (Math.random() >= additionalLangProbability) {
        addMoreLang = false
      }
    }

    return generatedLanguageList
  }

  generateManner = () => {
    const {isAbleToSpeak} = this.interactions
    const availablemannerList = isAbleToSpeak
      ? mannerList
      : mannerList.filter(({shouldAbleToSpeak}) => !shouldAbleToSpeak)

    return pickByPropability(availablemannerList).description
  }

  generateStory = () => {
    const story = pickByPropability(storyList)
    let text = ''

    switch (story.id) {
      case 'protector':
      case 'doom': {
        const {plural: {nominative, genitive}} = getRandomKind()

        text = story.description
          .replace(
            'какой-либо культуры или вида существ',
            `*${genitive}*`
          )
          .replace(
            'культура или существа',
            `*${nominative}*`
          )
        break
      }

      case 'religion-require':
      case 'religion-steal':
      case 'religion-use': {
        const {nameShort, nameFull, id} = getRandomGod()

        text = story.description
          .replace(
            'какому-либо богу',
            `*[${nameFull.dative}](GOD:${id})*`
          )
          .replace(
            'Последователи бога',
            `Последователи *[${nameShort.genitive}](GOD:${id})*`
          )
          .replace(
            'того же бога',
            `*[${nameShort.genitive}](GOD:${id})*`
          )
        break
      }

      default:
        text = story.description
    }

    if (text.includes('★ПЕРСОНАЖ★')) {
      const genderId = Math.random() > 0.5
          ? GENDER_FEMALE
          : GENDER_MALE
      const {id: raceId} = pickByPropability(pcRaceList)

      text = text
        .replace(
          '★ПЕРСОНАЖ★',
          `*${generateFullName({raceId, genderId})}*`,
        )
        .replace(
          '★ЛЮДЕЙ★',
          `*${pcRaceCollection[raceId].name.plural.genitive}*`,
        )
        .replace(
          '★ЧЕЛОВЕКА★',
          `*${pcRaceCollection[raceId].name.singular[genderId].genitive}*`,
        )
        .replace(
          '★ЧЕЛОВЕК★',
          `*${upLetter(pcRaceCollection[raceId].name.singular[genderId].nominative)}*`,
        )
    }

    return {
      ...story,
      description: text,
    }
  }

  generateLesserAbility = () => {
    const lesserAbility = pickByPropability(lesserAbilityList)

    switch (lesserAbility.id) {
      case 'language': {
        const langId = pickByPropability(withoutCommonLanguageList).id
        const {name: {nominative: langName}} = languageCollection[langId]
        const description = lesserAbility.description.replace(
          'на одном дополнительном языке (по выбору Мастера)',
          `на одном дополнительном языке: *${langName}*`
        )

        return {
          ...lesserAbility,
          description,
        }
      }

      case 'sentry': {
        const creatureTypeId = pickByPropability(creatureTypeList).id
        const {name} = creatureTypeCollection[creatureTypeId]
        const description = lesserAbility.description
          .replace(
            'Выберите какой-либо вид существ — они были врагами создателя предмета',
            `Врагами создателя предмета были *${name.plural.nominative}*`
          )
          .replace(
            'такие существа',
            `*${name.plural.nominative}*`
          )

        return {
          ...lesserAbility,
          description,
        }
      }

      default:
        return lesserAbility
    }
  }

  generateGoal = () => {
    let goal = pickByPropability(goalList)

    while (goal.id === 'alignment' && this.alignmentId === ALIGNMENT_N) {
      goal = pickByPropability(goalList)
    }

    switch (goal.id) {
      case 'alignment': {
        const {oppositeId} = aligmentCollection[this.alignmentId]
        const oppositeAligment = aligmentCollection[oppositeId]
        const description = goal.description.replace(
          'тех, чьё мировоззрение диаметрально противоположно его собственному (такой предмет не может быть нейтральным)',
          `существ с *${oppositeAligment.name[GENDER_MIDDLE].instrumental}* мировоззрением`,
        )

        return {
          ...goal,
          description,
        }
      }

      case 'defender':
      case 'doom_conquest':
      case 'doom_destroy': {
        const kind = getRandomKind()
        const description = kind
          ? goal.description.replace(
            'определённую расу или существ определённого вида',
            `*${kind.plural.accusative}*`
          )
          : goal.description

        return {
          ...goal,
          description,
        }
      }

      case 'crusader_loosen':
      case 'crusader_won':
      case 'crusader_destroy':
      case 'tamplier': {
        const god = getRandomGod()
        const description = goal.description.replace(
          'определённого божества',
          `*[${god.nameFull.genitive}](GOD:${god.id})*`
        )

        return {
          ...goal,
          description,
        }
      }

      default: {
        return goal
      }
    }
  }

  transformByName = text => itemTransformText({name: this.name, genderId: this.genderId})(text)
  transformByType = text => itemTransformText({name: this.name, genderId: gearCollection[this.gearId].genderId})(text)

  generateDescCreator = () => {
    const {
      creator,
      gearId,
    } = this

    const {
      name: typeName,
    } = gearCollection[gearId]

    const creatorName = creatureTypeCollection[creator.creatureTypeId].name.plural.instrumental
    const typeNameSmall = typeName.toLowerCase()
    const creatorText = this.transformByType(`★Этот★ [${typeNameSmall}](GEAR:${gearId}) ★создан★ ${creatorName}. ${creator.description}`)
    return  {
      header: 'Создатель',
      text: creatorText,
    }
  }

  generateDescSentience = () => {
    const {
      alignmentId,
      char,
      gearId,
      interactions,
      manner,
      name,
      senses,
      stats,
    } = this

    const {
      genderId: typeGenderId,
      name: typeName,
    } = gearCollection[gearId]

    const alignment = aligmentCollection[alignmentId].name[typeGenderId].nominative // TODO: handle plural
    const typeNameSmall = typeName.toLowerCase()

    const sentienceText = `${name} — ${this.transformByType(`★разумный★ ${alignment}`)} [${typeNameSmall}](GEAR:${gearId}) с Интеллектом ${stats[PARAM_INT]}, Мудростью ${stats[PARAM_WIT]} и Харизмой ${stats[PARAM_CHA]}. ★Он★ ${char.good}, но ${char.bad}.`

    return  {
      header: 'Разум',
      text: `${sentienceText}

### Чувства

${senses}

### Манера общения

${interactions} ${manner}`,
    }
  }

  generateDescCharacter = () => {
    const {
      bizzare,
      goal,
      lesserAbility,
      story,
    } = this

    return {
      header: 'Особенности',
      text: `### Малое свойство: ${lesserAbility.name}

${lesserAbility.description}

### Причудливая особенность: ${bizzare.name}

${bizzare.description}

### Предыстория: ${story.name}

${story.description}

### Цель: ${goal.name}

${goal.description}
Если владелец отказывается следовать целям предмета, возникает конфликт.`,
    }
  }

  generateDescPersonality = () => {
    const {
      bond,
      communication,
      ideal,
      weakness,
    } = this

    return  {
      header: 'Личность',
      text: `### Идеал

${ideal}

### Характер

${communication}

### Привязанность

${bond}

### Слабость

${weakness}`,
    }
  }

  generateDescription = () => {
    const creatorDescr = this.generateDescCreator()
    const sentienceDescr = this.generateDescSentience()
    const characterDescr = this.generateDescCharacter()
    const personalityDescr = this.generateDescPersonality()

    return [
      creatorDescr,
      sentienceDescr,
      characterDescr,
      personalityDescr,
    ].map(
      ({ header, text }) => ({
        header,
        text: this.transformByName(text),
      })
    )
  }
}
