import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DescriptionList from '@/constructors/DescriptionList'
import MagicItem from '@/components/MagicItem'

export default class SentientItemContainer extends Component {
  static propTypes = {
    item: PropTypes.shape({
      gearId: PropTypes.string.isRequired,
      genderId: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }

  render() {
    const {
      item: {
        gearId,
        genderId,
        description,
        name,
      },
    } = this.props

    const magicItem = {
      name,
      description,
      genderId,
      gearType: gearId,
    }

    magicItem.description = new DescriptionList(magicItem)

    return (
      <MagicItem magicItem={magicItem}/>
    )
  }
}
