export default [
  {
    id: 'sings-bad',
    probabilityWeight: 12,
    description: 'Иногда ★он★ ★может★ внезапно начать громко петь какую-то очень плохую песню.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'sings-good',
    probabilityWeight: 12,
    description: 'Иногда ★он★ ★может★ внезапно начать петь какую-нибудь очень прилипчивую песенку.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'sings-funny',
    probabilityWeight: 12,
    description: '★Он★ часто ★напевает★ весёлые, дурашливые песенки.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'sings-silent',
    probabilityWeight: 12,
    description: '★Он★ ★склонен★ тихо напевать спокойные, умиротворяющие песни.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'whistle-softly',
    probabilityWeight: 48,
    description: '★Он★ ★склонен★ тихо насвистывать.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'moo-softly',
    probabilityWeight: 48,
    description: '★Он★ ★склонен★ что-то тихо мычать.',
    shouldAbleToSpeak: false,
  },
  {
    id: 'speaks-rhymes',
    probabilityWeight: 36,
    description: '★Он★ ★говорит★ рифмами.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'speaks-rhymes',
    probabilityWeight: 36,
    description: '★Он★ всегда ★говорит★ рифмами и ★понимает★ только рифмованую речь и надписи.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'speaks-fancifully',
    probabilityWeight: 72,
    description: 'Говорит причудливо.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'quiet-voice',
    probabilityWeight: 36,
    description: 'У ★него★ очень тихий голос. Во время разговора с ★ним★ приходится напрягать слух.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'quiet-voice',
    probabilityWeight: 36,
    description: 'У ★него★ тихий голос.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'loud-voice',
    probabilityWeight: 72,
    description: 'У ★него★ громкий голос.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'very-loud-voice',
    probabilityWeight: 72,
    description: 'У ★него★ очень громкий голос, ★Предмет★ ★выкрикивает★ слова настолько громко, насколько ★может★.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'swallows-words--fast',
    probabilityWeight: 12,
    description: '★Он★ ★говорит★ настолько быстро, что иногда ★проглатывает★ целые слова, что может искажать смысл ★его★ слов.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'swallows-words-end',
    probabilityWeight: 12,
    description: '★Он★ ★проглатывает★ окончания слов.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'swallows-words-start',
    probabilityWeight: 12,
    description: '★Он★ часто ★проглатывает★ начало слов.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'cant-into-letter-R',
    probabilityWeight: 3,
    description: '★Он★ не ★выговаривает★ букву «Р».',
    shouldAbleToSpeak: true,
  },
  {
    id: 'cant-into-letter-L',
    probabilityWeight: 3,
    description: '★Он★ не ★выговаривает★ букву «Л».',
    shouldAbleToSpeak: true,
  },
  {
    id: 'cant-into-letter-V',
    probabilityWeight: 3,
    description: '★Он★ не ★выговаривает★ букву «В».',
    shouldAbleToSpeak: true,
  },
  {
    id: 'cant-into-letter-G',
    probabilityWeight: 3,
    description: '★Он★ не ★выговаривает★ букву «Г».',
    shouldAbleToSpeak: true,
  },
  {
    id: 'lisps-hard',
    probabilityWeight: 24,
    description: '★Он★ ужасно ★шепелявит★.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'lisps-light',
    probabilityWeight: 24,
    description: '★Он★ слегка ★шепелявит★.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'stutters',
    probabilityWeight: 48,
    description: '★Он★ ★заикается★.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'speaks-too-clearly',
    probabilityWeight: 144,
    description: '★Он★ чрезвычайно чётко ★проговаривает★ слова.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'speaks-too-loudly',
    probabilityWeight: 144,
    description: '★Он★ ★говорит★ очень громко.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'whisper',
    probabilityWeight: 144,
    description: '★Он★ ★разговаривает★ только шёпотом.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'pompous-talk',
    probabilityWeight: 72,
    description: '★Он★ ★использует★ помпезную речь.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'long-words',
    probabilityWeight: 24,
    description: '★Он★ ★предпочитает★ использовать длинные слова.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'long-words',
    probabilityWeight: 24,
    description: '★Он★ ★предпочитает★ использовать короткие слова. Если короткого слова не существует — ★придумает★ его.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'long-words',
    probabilityWeight: 24,
    description: '★Он★ ★предпочитает★ использовать умно звучащие слова.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'wrong-words',
    probabilityWeight: 72,
    description: '★Он★ часто ★использует★ неправильные слова.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'wrong-words',
    probabilityWeight: 72,
    description: '★Он★ иногда намеренно ★искажает★ слова, потому что обычные слова ★ему★ не нравятся.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'swears',
    probabilityWeight: 72,
    description: '★Он★ часто ★клянётся★ — как к месту, так и не к месту.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'pretentious',
    probabilityWeight: 72,
    description: '★Он★ часто ★произносит★ пафосные фразы.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'jokes',
    probabilityWeight: 144,
    description: '★Он★ постоянно ★шутит★ и ★каламбурит★.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'predicts-failures',
    probabilityWeight: 144,
    description: '★Он★ часто ★предрекает★ неудачи замогильным голосом.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'twitches',
    probabilityWeight: 144,
    description: '★Он★ часто нервно ★дёргается★.',
    shouldAbleToSpeak: false,
  },
  {
    id: 'color-switch',
    probabilityWeight: 144,
    description: '★Предмет★ иногда ★меняет★ цвет.',
    shouldAbleToSpeak: false,
  },
  {
    id: 'pupsik',
    probabilityWeight: 12,
    description: '★Он★ называет владельца «Пупсик», но не замечает этого.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'jemanno',
    probabilityWeight: 144,
    description: '★Он★ ★говорит★ очень жеманно.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'vejliv',
    probabilityWeight: 144,
    description: '★Он★ чрезвычайно ★вежлив★.',
    shouldAbleToSpeak: true,
  },
  {
    id: 'skripit',
    probabilityWeight: 144,
    description: '★Он★ ★поскрипывает★ при использовании.',
    shouldAbleToSpeak: false,
  },
]
