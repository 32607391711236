import pcRaceIdList, {PC_RACE_CUSTOM} from '@/constants/pcRaceIdList'

export default pcRaceIdList
  .filter(
    id => id !== PC_RACE_CUSTOM
  )
  .map(
    id => ({
      id,
      probabilityWeight: 1,
    })
  )
