export default [
  {
    id: 'krasota',
    probabilityWeight: 1,
    description: 'Красота',
    alignmentId: 'any',
  },
  {
    id: 'blagotvoritelnost',
    probabilityWeight: 1,
    description: 'Благотворительность',
    alignmentId: 'any_good',
  },
  {
    id: 'vysshee blago',
    probabilityWeight: 1,
    description: 'Высшее благо',
    alignmentId: 'any_good',
  },
  {
    id: 'zhizn',
    probabilityWeight: 1,
    description: 'Жизнь',
    alignmentId: 'any_good',
  },
  {
    id: 'uvazhenie',
    probabilityWeight: 1,
    description: 'Уважение',
    alignmentId: 'any_good',
  },
  {
    id: 'samopozhertvovanie',
    probabilityWeight: 1,
    description: 'Самопожертвование',
    alignmentId: 'any_good',
  },
  {
    id: 'dominirovanie',
    probabilityWeight: 1,
    description: 'Доминирование',
    alignmentId: 'any_evil',
  },
  {
    id: 'zhadnost',
    probabilityWeight: 1,
    description: 'Жадность',
    alignmentId: 'any_evil',
  },
  {
    id: 'moshch',
    probabilityWeight: 1,
    description: 'Мощь',
    alignmentId: 'any_evil',
  },
  {
    id: 'bol',
    probabilityWeight: 1,
    description: 'Боль',
    alignmentId: 'any_evil',
  },
  {
    id: 'kara',
    probabilityWeight: 1,
    description: 'Кара',
    alignmentId: 'any_evil',
  },
  {
    id: 'reznia',
    probabilityWeight: 1,
    description: 'Резня',
    alignmentId: 'any_evil',
  },
  {
    id: 'peremeny',
    probabilityWeight: 1,
    description: 'Перемены',
    alignmentId: 'any_chaotic',
  },
  {
    id: 'nahodchivost',
    probabilityWeight: 1,
    description: 'Находчивость',
    alignmentId: 'any_chaotic',
  },
  {
    id: 'svoboda',
    probabilityWeight: 1,
    description: 'Свобода',
    alignmentId: 'any_chaotic',
  },
  {
    id: 'nezavisimost',
    probabilityWeight: 1,
    description: 'Независимость',
    alignmentId: 'any_chaotic',
  },
  {
    id: 'otsutstvie_ogranichenii',
    probabilityWeight: 1,
    description: 'Отсутствие ограничений',
    alignmentId: 'any_chaotic',
  },
  {
    id: 'kaprizy',
    probabilityWeight: 1,
    description: 'Капризы',
    alignmentId: 'any_chaotic',
  },
  {
    id: 'obshchestvo',
    probabilityWeight: 1,
    description: 'Общество',
    alignmentId: 'any_lawful',
  },
  {
    id: 'chestnost',
    probabilityWeight: 1,
    description: 'Честность',
    alignmentId: 'any_lawful',
  },
  {
    id: 'chest',
    probabilityWeight: 1,
    description: 'Честь',
    alignmentId: 'any_lawful',
  },
  {
    id: 'logika',
    probabilityWeight: 1,
    description: 'Логика',
    alignmentId: 'any_lawful',
  },
  {
    id: 'otvetstvennost',
    probabilityWeight: 1,
    description: 'Ответственность',
    alignmentId: 'any_lawful',
  },
  {
    id: 'traditcii',
    probabilityWeight: 1,
    description: 'Традиции',
    alignmentId: 'any_lawful',
  },
  {
    id: 'ravnovesie',
    probabilityWeight: 1,
    description: 'Равновесие',
    alignmentId: 'any_neutral',
  },
  {
    id: 'znanie',
    probabilityWeight: 1,
    description: 'Знание',
    alignmentId: 'any_neutral',
  },
  {
    id: 'sam_zhivi_i_drugim_ne_meshai',
    probabilityWeight: 1,
    description: 'Сам живи и другим не мешай',
    alignmentId: 'any_neutral',
  },
  {
    id: 'umerennost',
    probabilityWeight: 1,
    description: 'Умеренность',
    alignmentId: 'any_neutral',
  },
  {
    id: 'neitralnost',
    probabilityWeight: 1,
    description: 'Нейтральность',
    alignmentId: 'n',
  },
  {
    id: 'narod',
    probabilityWeight: 1,
    description: 'Народ',
    alignmentId: 'not_evil',
  },
  {
    id: 'stremlenie',
    probabilityWeight: 1,
    description: 'Стремление',
    alignmentId: 'any',
  },
  {
    id: 'issledovaniia',
    probabilityWeight: 1,
    description: 'Исследования',
    alignmentId: 'any_neutral',
  },
  {
    id: 'slava',
    probabilityWeight: 1,
    description: 'Слава',
    alignmentId: 'any',
  },
  {
    id: 'derzhava',
    probabilityWeight: 1,
    description: 'Держава',
    alignmentId: 'any_lawful',
  },
  {
    id: 'iskuplenie',
    probabilityWeight: 1,
    description: 'Искупление',
    alignmentId: 'not_neutral',
  },
  {
    id: 'samopoznanie',
    probabilityWeight: 1,
    description: 'Самопознание',
    alignmentId: 'any',
  },
]
