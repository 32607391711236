export default [
  {
    id: 'beacon',
    probabilityWeight: 12,
    name: 'Маяк',
    description: 'Владелец может бонусным действием заставить предмет источать яркий свет в радиусе 10 футов и тусклый в пределах ещё 10 футов или погасить его.',
  },
  {
    id: 'compass',
    probabilityWeight: 12,
    name: 'Компас',
    description: 'Владелец может действием определить, в каком направлении находится север.',
  },
  {
    id: 'conscientious',
    probabilityWeight: 12,
    name: 'Добросовестный',
    description: 'Когда владелец замышляет или предпринимает какое-либо злобное деяние, то ★Предмет★ усиливает его угрызения совести.',
  },
  {
    id: 'under_earth_dweller',
    probabilityWeight: 12,
    name: 'Подземный житель',
    description: 'Находясь под землёй, владелец всегда знает расстояние до поверхности земли и направление до ближайшей лестницы, подъёма, или любого другого пути, ведущего наверх.',
  },
  {
    id: 'shiny',
    probabilityWeight: 12,
    name: 'Сверкающий',
    description: '★Предмет★ никогда не покрывается грязью.',
  },
  {
    id: 'guard',
    probabilityWeight: 12,
    name: 'Страж',
    description: '★Предмет★ шепчет предупреждения об опасностях, грозящих своему владельцу, предоставляя ему бонус **+2** к инициативе, при условии, что тот дееспособен.',
  },
  {
    id: 'garmonic',
    probabilityWeight: 12,
    name: 'Гармоничный',
    description: 'Для настройки на этот предмет понадобится всего одна минута.',
  },
  {
    id: 'hidden_message_moon',
    probabilityWeight: 4,
    name: 'Скрытое сообщение',
    description: 'Где-то на поверхности предмета скрыто сообщение, которое можно увидеть только при определённой фазе Луны.',
  },
  {
    id: 'hidden_message_season',
    probabilityWeight: 4,
    name: 'Скрытое сообщение',
    description: 'Где-то на поверхности предмета скрыто сообщение, которое можно увидеть только в определённое время года.',
  },
  {
    id: 'hidden_message_place',
    probabilityWeight: 4,
    name: 'Скрытое сообщение',
    description: 'Где-то на поверхности предмета скрыто сообщение, которое можно увидеть только находясь в определённом месте.',
  },
  {
    id: 'key_camera',
    probabilityWeight: 3,
    name: 'Ключ',
    description: '★Предмет★ используется для того, чтобы отпереть некую камеру.',
  },
  {
    id: 'key_crypt',
    probabilityWeight: 3,
    name: 'Ключ',
    description: '★Предмет★ используется для того, чтобы отпереть некий склеп.',
  },
  {
    id: 'key_container',
    probabilityWeight: 3,
    name: 'Ключ',
    description: '★Предмет★ используется для того, чтобы отпереть некий контейнер.',
  },
  {
    id: 'key_room',
    probabilityWeight: 3,
    name: 'Ключ',
    description: '★Предмет★ используется для того, чтобы отпереть некое помещение.',
  },
  {
    id: 'language',
    probabilityWeight: 12,
    name: 'Язык',
    description: 'Пока ★Предмет★ находится у владельца, тот может понимать и разговаривать на одном дополнительном языке (по выбору Мастера).',
  },
  {
    id: 'sentry',
    probabilityWeight: 12,
    name: 'Часовой',
    description: 'Выберите какой-либо вид существ — они были врагами создателя предмета. ★Предмет★ начинает слабо светиться, когда такие существа находятся в пределах 120 футов от него.',
  },
  {
    id: 'song-creation',
    probabilityWeight: 12,
    name: 'Песнь создания',
    description: 'Каждый раз, когда ★Предмет★ ★ударяется★ или ★используется★ для удара по врагу, ★его★ владелец слышит фрагменты древней песни.',
  },
  {
    id: 'strange-material',
    probabilityWeight: 12,
    name: 'Странный материал',
    description: '★Предмет★ был сделан из материала, который является *весьма* необычным, учитывая ★его★ предназначение. На прочности предмета это никак не отражается.',
  },
  {
    id: 'moderate',
    probabilityWeight: 12,
    name: 'Умеренный',
    description: 'Владелец предмета не страдает ни от низких, ни от высоких температур в диапазоне от −28 до +48 °С',
  },
  {
    id: 'indestructible',
    probabilityWeight: 12,
    name: 'Неразрушаемый',
    description: '★Предмет★ не ★может★ быть ★сломан★. Для того чтобы ★его★ разрушить, должны быть использованы специальные средства.',
  },
  {
    id: 'leader',
    probabilityWeight: 12,
    name: 'Полководец',
    description: 'Владелец может действием заставить свой голос быть ясно слышимым на расстоянии до 300 футов. Эффект длится до конца следующего хода владельца.',
  },
  {
    id: 'deep_born',
    probabilityWeight: 12,
    name: 'Рождённый пучиной',
    description: '★Предмет★ не тонет в воде и любых других жидкостях. ★Его★ владелец совершает с преимуществом проверки *Силы (Атлетика)*, когда он пытается плыть.',
  },
  {
    id: 'evil',
    probabilityWeight: 12,
    name: 'Злой',
    description: 'Когда владельцу предмета предоставляется возможность совершить какой-либо эгоистичный поступок или пойти по тёмному пути, ★Предмет★ будет усиливать его желание сделать это.',
  },
  {
    id: 'illusion',
    probabilityWeight: 12,
    name: 'Иллюзия',
    description: '★Предмет★ ★наполнен★ магией иллюзии, позволяющей владельцу изменять ★его★ вид в небольших деталях. Такие изменения не влияют на то, как ★Предмет★ ★надевается★, ★носится★ или ★держится★ в руках, и не оказывает эффект на другие ★его★ магические свойства. Например, владелец может сделать так, чтобы красная мантия казалась синей или заставить кольцо из золота выглядеть изготовленным из слоновой кости. ★Предмет★ возвращает свой истинный облик, если ★его★ никто не несёт и не носит.',
  },
]






































