export default [
  {
    id: 'alignment',
    probabilityWeight: 6,
    name: 'Мировоззрение',
    description: '★Предмет★ ★стремится★ покорять или уничтожать тех, чьё мировоззрение диаметрально противоположно его собственному (такой предмет не может быть нейтральным).',
  },
  {
    id: 'doom_conquest',
    probabilityWeight: 3,
    name: 'Погибель',
    description: '★Предмет★ ★стремится★ покорять определённую расу или существ определённого вида.',
  },
  {
    id: 'doom_destroy',
    probabilityWeight: 3,
    name: 'Погибель',
    description: '★Предмет★ ★стремится★ уничтожать определённую расу или существ определённого вида.',
  },
  {
    id: 'defender',
    probabilityWeight: 6,
    name: 'Защитник',
    description: '★Предмет★ ★стремится★ защищать определённую расу или существ определённого вида.',
  },
  {
    id: 'crusader_won',
    probabilityWeight: 2,
    name: 'Крестоносец',
    description: '★Предмет★ ★стремится★ побеждать слуг определённого божества.',
  },
  {
    id: 'crusader_loosen',
    probabilityWeight: 2,
    name: 'Крестоносец',
    description: '★Предмет★ ★стремится★ ослаблять слуг определённого божества.',
  },
  {
    id: 'crusader_destroy',
    probabilityWeight: 2,
    name: 'Крестоносец',
    description: '★Предмет★ ★стремится★ уничтожать слуг определённого божества.',
  },
  {
    id: 'tamplier',
    probabilityWeight: 6,
    name: 'Тамплиер',
    description: '★Предмет★ ★стремится★ защищать слуг и интересы определённого божества.',
  },
  {
    id: 'destroyer',
    probabilityWeight: 6,
    name: 'Уничтожитель',
    description: '★Предмет★ ★обожает★ разрушения и ★подстрекает★ владельца постоянно сражаться.',
  },
  {
    id: 'glory_seeker_celebrity',
    probabilityWeight: 3,
    name: 'Искатель славы',
    description: '★Предмет★ ★стремится★ прослыть самым великим магическим предметом в мире, сделав владельца знаменитостью.',
  },
  {
    id: 'glory_seeker_scandal',
    probabilityWeight: 3,
    name: 'Искатель славы',
    description: '★Предмет★ ★стремится★ прослыть самым великим магическим предметом в мире, сделав владельца скандальной личностью.',
  },
  {
    id: 'knowledge_seeker_lore',
    probabilityWeight: 2,
    name: 'Искатель знаний',
    description: '★Предмет★ ★стремится★ искать знания.',
  },
  {
    id: 'knowledge_seeker_mystery',
    probabilityWeight: 2,
    name: 'Искатель знаний',
    description: '★Предмет★ ★стремится★ разгадывать тайны.',
  },
  {
    id: 'knowledge_seeker_prophecy',
    probabilityWeight: 2,
    name: 'Искатель знаний',
    description: '★Предмет★ ★стремится★ расшифровывать таинственные пророчества.',
  },
  {
    id: 'destiny_seeker',
    probabilityWeight: 6,
    name: 'Искатель судьбы',
    description: '★Предмет★ ★убеждён★, что ★он★ и ★его★ владелец играют ключевые роли в будущих событиях.',
  },
  {
    id: 'creator_seeker',
    probabilityWeight: 6,
    name: 'Искатель создателя',
    description: '★Предмет★ ★ищет★ своего создателя и ★хочет★ понять, зачем ★он★ ★был★ ★создан★.',
  },
]
