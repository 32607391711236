import React, { Component } from "react"

import trackAnalyticEvent from '@/utils/trackAnalyticEvent'

import SentientItem from "./SentientItem"
import SentientItemGeneratorComponent from "./SentientItemGeneratorComponent"

class SentientItemGeneratorContainer extends Component {
  state = {
    item: new SentientItem(),
  }

  onGenerate = () => {
    this.setState({
      item: new SentientItem(),
    })

    trackAnalyticEvent('generate-sentient-item')
  }

  render() {
    const {item} = this.state

    return (
      <SentientItemGeneratorComponent
        item={item}
        onGenerate={this.onGenerate}
      />
    )
  }
}

export default SentientItemGeneratorContainer
