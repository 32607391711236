export default [
  {
    id: 'common_30',
    probabilityWeight: 10,
    description: '★Предмет★ ★обладает★ слухом и обычным зрением в пределах 30 футов.',
  },
  {
    id: 'common_60',
    probabilityWeight: 10,
    description: '★Предмет★ ★обладает★ слухом и обычным зрением в пределах 60 футов.',
  },
  {
    id: 'common_120',
    probabilityWeight: 10,
    description: '★Предмет★ ★обладает★ слухом и обычным зрением в пределах 120 футов.',
  },
  {
    id: 'dark_120',
    probabilityWeight: 10,
    description: '★Предмет★ ★обладает★ слухом и тёмным зрением в пределах 120 футов.',
  },
  {
    id: 'true_120',
    probabilityWeight: 1,
    description: '★Предмет★ ★обладает★ слухом и истинным зрением в пределах 120 футов.',
  },
]
