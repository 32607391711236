const creaturesNearText = `какой-либо культуры или вида существ. Если культура или существа находятся рядом, они могут опознать ★его★ и посчитать ★его★ владельца`
const ornamentText = `★Предмет★ ★был★ ★создан★ для какого-то специального события. ★Он★ ★инкрустирован★ драгоценными камнями, вставками из`
const prophecyText = `★Предмет★ ★фигурирует★ в пророчестве: ★его★ владельцу суждено сыграть ключевую роль в будущих событиях. ★ЧЕЛОВЕК★ ★ПЕРСОНАЖ★ хочет`
const religionText = `★Предмет★ ★использовался★ в религиозных обрядах, посвящённых какому-либо богу. На ★нём★ изображены священные символы. Последователи бога`
const mysticOnslaughtText = `★Предмет★ ★связан★ с какой-то резнёй.` // TODO: какой?
const mysticMurderText = `★Предмет★ ★связан★ с каким-то убийством.` // TODO: какое?
const mysticSuspicionText = `Те, кто знаком с историей этого предмета, будут относиться к ★нему★ и ★его★ новому владельцу с подозрением.`
const mysticNameText = `На ★нём★ есть имя: ★ПЕРСОНАЖ★. Это имя ★ЧЕЛОВЕКА★ — злодея, который использовал ★его★ раньше.` // TODO: какого?
const mysticSomethingText = `На ★нём★ есть что-то напоминающее о злодее, который использовал ★его★ раньше.`
const powerRoyalText = `★Предмет★ когда-то ★был★ частью королевских регалий.` // TODO: чьих?
const powerPositionText = `★Предмет★ когда-то ★был★ знаком высокого поста.` // TODO: какого?
const powerOwnerBackText = `★Его★ бывший владелец хочет ★его★ вернуть.`
const powerChildBackText = `Потомки ★его★ бывшего владельца хотят ★его★ вернуть.`
const powerMistakeText = `Кто-нибудь может по ошибке решить, что новый обладатель этого предмета является ★его★ законным наследником.`

export default [
  {
    id: 'magic',
    probabilityWeight: 12,
    name: 'Магия',
    description: '★Предмет★ ★был★ ★создан★ древним орденом заклинателей и ★содержит★ его символ.',
  },
  {
    id: 'doom',
    probabilityWeight: 12,
    name: 'Погибель',
    description: `★Предмет★ ★был★ ★создан★ врагами ${creaturesNearText} врагом.`,
  },
  {
    id: 'protector',
    probabilityWeight: 12,
    name: 'Защитник',
    description: `★Предмет★ ★был★ ★создан★ союзниками ${creaturesNearText} союзником.`,
  },
  {
    id: 'heroism',
    probabilityWeight: 12,
    name: 'Героизм',
    description: 'Великий герой ★ЛЮДЕЙ★ ★ПЕРСОНАЖ★ когда-то владел этим предметом. Все, кому знакома его история, будут ожидать от текущего владельца великих деяний.',
  },
  {
    id: 'ornament-gold-gold',
    probabilityWeight: 3,
    name: 'Орнамент',
    description: `${ornamentText} золота и золотыми узорами на поверхности.`,
  },
  {
    id: 'ornament-gold-silver',
    probabilityWeight: 3,
    name: 'Орнамент',
    description: `${ornamentText} золота и серебряными узорами на поверхности.`,
  },
  {
    id: 'ornament-platinum-gold',
    probabilityWeight: 3,
    name: 'Орнамент',
    description: `${ornamentText} платины и золотыми узорами на поверхности.`,
  },
  {
    id: 'ornament-platinum-silver',
    probabilityWeight: 3,
    name: 'Орнамент',
    description: `${ornamentText} платины и серебряными узорами на поверхности.`,
  },
  {
    id: 'prophecy-steal',
    probabilityWeight: 6,
    name: 'Пророчество',
    description: `${prophecyText} сыграть эту роль и попробует украсть ★его★.`,
  },
  {
    id: 'prophecy-kill',
    probabilityWeight: 6,
    name: 'Пророчество',
    description: `${prophecyText} предотвратить исполнение пророчества и попытается убить ★его★ владельца.`,
  },
  {
    id: 'religion-require',
    probabilityWeight: 4,
    name: 'Религия',
    description: `${religionText} попытаются склонить ★его★ владельца отдать этот предмет храму.`,
  },
  {
    id: 'religion-steal',
    probabilityWeight: 4,
    name: 'Религия',
    description: `${religionText} попытаются украсть ★его★ для себя.`,
  },
  {
    id: 'religion-use',
    probabilityWeight: 4,
    name: 'Религия',
    description: `${religionText} будут поощрять ★его★ использование паладином или жрецом того же бога.`,
  },
  {
    id: 'mystic-onslaught-suspicion',
    probabilityWeight: 2,
    name: 'Мистика',
    description: `${mysticOnslaughtText} ${mysticSuspicionText}`,
  },
  {
    id: 'mystic-onslaught-name',
    probabilityWeight: 2,
    name: 'Мистика',
    description: `${mysticOnslaughtText} ${mysticNameText} ${mysticSuspicionText}`,
  },
  {
    id: 'mystic-onslaught-something',
    probabilityWeight: 2,
    name: 'Мистика',
    description: `${mysticOnslaughtText} ${mysticSomethingText} ${mysticSuspicionText}`,
  },
  {
    id: 'mystic-murder-suspicion',
    probabilityWeight: 2,
    name: 'Мистика',
    description: `${mysticMurderText} ${mysticSuspicionText}`,
  },
  {
    id: 'mystic-murder-name',
    probabilityWeight: 2,
    name: 'Мистика',
    description: `${mysticMurderText} ${mysticNameText} ${mysticSuspicionText}`,
  },
  {
    id: 'mystic-murder-something',
    probabilityWeight: 2,
    name: 'Мистика',
    description: `${mysticMurderText} ${mysticSomethingText} ${mysticSuspicionText}`,
  },
  {
    id: 'power-royal-ex',
    probabilityWeight: 2,
    name: 'Символ власти',
    description: `${powerRoyalText} ${powerOwnerBackText}`,
  },
  {
    id: 'power-royal-child',
    probabilityWeight: 2,
    name: 'Символ власти',
    description: `${powerRoyalText} ${powerChildBackText}`,
  },
  {
    id: 'power-royal-mistake',
    probabilityWeight: 2,
    name: 'Символ власти',
    description: `${powerRoyalText} ${powerMistakeText}`,
  },
  {
    id: 'power-position-ex',
    probabilityWeight: 2,
    name: 'Символ власти',
    description: `${powerPositionText} ${powerOwnerBackText}`,
  },
  {
    id: 'power-position-child',
    probabilityWeight: 2,
    name: 'Символ власти',
    description: `${powerPositionText} ${powerChildBackText}`,
  },
  {
    id: 'power-position-mistake',
    probabilityWeight: 2,
    name: 'Символ власти',
    description: `${powerPositionText} ${powerMistakeText}`,
  },
]







