export default [
  {
    id: 'stupid',
    probabilityWeight: 1,
    description: '★глупый★',
  },
  {
    id: 'dimwitted',
    probabilityWeight: 1,
    description: '★недалёкий★',
  },
  {
    id: 'forgetful',
    probabilityWeight: 1,
    description: '★забывчивый★',
  },
  {
    id: 'scattered',
    probabilityWeight: 1,
    description: '★рассеянный★',
  },
  {
    id: 'boring',
    probabilityWeight: 1,
    description: '★скучный★',
  },
  {
    id: 'monotone',
    probabilityWeight: 1,
    description: '★занудный★',
  },
  {
    id: 'trusliv',
    probabilityWeight: 1,
    description: '★трусливый★',
  },
  {
    id: 'hamovat',
    probabilityWeight: 1,
    description: '★хамоватый★',
  },
  {
    id: 'leniv',
    probabilityWeight: 1,
    description: '★ленивый★',
  },
  {
    id: 'vzdoren',
    probabilityWeight: 1,
    description: '★вздорный★',
  },
  {
    id: 'boltliv',
    probabilityWeight: 1,
    description: '★болтливый★',
  },
  {
    id: 'brezgliv',
    probabilityWeight: 1,
    description: '★брезгливый★',
  },
  {
    id: 'nadmenen',
    probabilityWeight: 1,
    description: '★надменный★',
  },
  {
    id: 'zlopamiatnyi',
    probabilityWeight: 1,
    description: '★злопамятный★',
  },
]
