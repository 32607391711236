import {
  GEAR_ABACUS,
  GEAR_AMULET,
  GEAR_BACKPACK,
  GEAR_BAGPIPES,
  GEAR_BARREL,
  GEAR_BASKET,
  GEAR_BATTLEAXE,
  GEAR_BEDROLL,
  GEAR_BELL,
  GEAR_BIT_AND_BRIDLE,
  GEAR_BLANKET,
  GEAR_BLOCK_AND_TACKLE,
  GEAR_BLOWGUN,
  GEAR_BOOK,
  GEAR_BOTTLE_GLASS,
  GEAR_BREASTPLATE,
  GEAR_BUCKET,
  GEAR_CANOE,
  GEAR_CARRIAGE,
  GEAR_CART,
  GEAR_CASE_CROSSBOW_BOLT,
  GEAR_CASE_MAP_OR_SCROLL,
  GEAR_CHAIN_MAIL,
  GEAR_CHAIN_SHIRT,
  GEAR_CHARIOT,
  GEAR_CHEST,
  GEAR_CLIMBER_S_KIT,
  GEAR_CLOTHES_COMMON,
  GEAR_CLOTHES_COSTUME,
  GEAR_CLOTHES_FINE,
  GEAR_CLOTHES_TRAVELERS,
  GEAR_CLUB,
  GEAR_COMPONENT_POUCH,
  GEAR_CROSSBOW_HAND,
  GEAR_CROSSBOW_HEAVY,
  GEAR_CROSSBOW_LIGHT,
  GEAR_CROWBAR,
  GEAR_CRYSTAL,
  GEAR_DAGGER,
  GEAR_DART,
  GEAR_DICE_SET,
  GEAR_DRAGON_CHESS,
  GEAR_DRUM,
  GEAR_DULCIMER,
  GEAR_EMBLEM,
  GEAR_EXOTIC_SADDLE,
  GEAR_FISHING_TACKLE,
  GEAR_FLAIL,
  GEAR_FLASK,
  GEAR_FLUTE,
  GEAR_GALLEY,
  GEAR_GLAIVE,
  GEAR_GRAPPLING_HOOK,
  GEAR_GREATAXE,
  GEAR_GREATCLUB,
  GEAR_GREATSWORD,
  GEAR_HALBERD,
  GEAR_HALF_PLATE,
  GEAR_HAMMER,
  GEAR_HAMMER_SLEDGE,
  GEAR_HANDAXE,
  GEAR_HIDE_ARMOR,
  GEAR_HORN,
  GEAR_HOURGLASS,
  GEAR_HUNTING_TRAP,
  GEAR_INK_PEN,
  GEAR_JAVELIN,
  GEAR_JUG,
  GEAR_KEELBOAT,
  GEAR_LADDER,
  GEAR_LAMP,
  GEAR_LANCE,
  GEAR_LANTERN_BULLSEYE,
  GEAR_LANTERN_HOODED,
  GEAR_LEATHER_ARMOR,
  GEAR_LIGHT_HAMMER,
  GEAR_LOCK,
  GEAR_LONGBOW,
  GEAR_LONGSHIP,
  GEAR_LONGSWORD,
  GEAR_LUTE,
  GEAR_LYRE,
  GEAR_MACE,
  GEAR_MAGNIFYING_GLASS,
  GEAR_MANACLES,
  GEAR_MAUL,
  GEAR_MESS_KIT,
  GEAR_MILITARY_SADDLE,
  GEAR_MIRROR_STEEL,
  GEAR_MORNINGSTAR,
  GEAR_NET,
  GEAR_ORB,
  GEAR_PACK_SADDLE,
  GEAR_PADDED_ARMOR,
  GEAR_PAN_FLUTE,
  GEAR_PIKE,
  GEAR_PITCHER,
  GEAR_PLATE_ARMOR,
  GEAR_PLAYING_CARD_SET,
  GEAR_POLE,
  GEAR_POT_IRON,
  GEAR_POUCH,
  GEAR_QUARTERSTAFF,
  GEAR_QUIVER,
  GEAR_RAIN_CATCHER,
  GEAR_RAM_PORTABLE,
  GEAR_RAPIER,
  GEAR_RELIQUARY,
  GEAR_RIDING_SADDLE,
  GEAR_RING_MAIL,
  GEAR_ROBES,
  GEAR_ROD,
  GEAR_ROWBOAT,
  GEAR_SACK,
  GEAR_SADDLEBAGS,
  GEAR_SAILING_SHIP,
  GEAR_SCALE_MAIL,
  GEAR_SCALE_MERCHANTS,
  GEAR_SCIMITAR,
  GEAR_SHAWM,
  GEAR_SHIELD,
  GEAR_SHORTBOW,
  GEAR_SHORTSWORD,
  GEAR_SHOVEL,
  GEAR_SICKLE,
  GEAR_SIGNAL_WHISTLE,
  GEAR_SIGNET_RING,
  GEAR_SLED,
  GEAR_SLING,
  GEAR_SPEAR,
  GEAR_SPELLBOOK,
  GEAR_SPLINT_ARMOR,
  GEAR_SPRIG_OF_HOLLY,
  GEAR_SPRIG_OF_MISTLETOE,
  GEAR_SPYGLASS,
  GEAR_STAFF,
  GEAR_STUDDED_LEATHER_ARMOR,
  GEAR_TANKARD,
  GEAR_TENT,
  GEAR_THREE_DRAGON_ANTE,
  GEAR_TINDERBOX,
  GEAR_TOTEM,
  GEAR_TRIDENT,
  GEAR_VIAL,
  GEAR_VIOL,
  GEAR_WAGON,
  GEAR_WAND,
  GEAR_WAR_PICK,
  GEAR_WARHAMMER,
  GEAR_WARSHIP,
  GEAR_WATERSKIN,
  GEAR_WHETSTONE,
  GEAR_WHIP,
  GEAR_WOODEN_STAFF,
  GEAR_YEW_SCEPTER,
  GEAR_YEW_WAND,
  GEAR_YKLWA,
} from '@/constants/gearIdList'

export default [
  {
    id: GEAR_PADDED_ARMOR,
    probabilityWeight: 50,
  },
  {
    id: GEAR_LEATHER_ARMOR,
    probabilityWeight: 50,
  },
  {
    id: GEAR_STUDDED_LEATHER_ARMOR,
    probabilityWeight: 50,
  },
  {
    id: GEAR_HIDE_ARMOR,
    probabilityWeight: 50,
  },
  {
    id: GEAR_CHAIN_SHIRT,
    probabilityWeight: 50,
  },
  {
    id: GEAR_SCALE_MAIL,
    probabilityWeight: 50,
  },
  {
    id: GEAR_BREASTPLATE,
    probabilityWeight: 50,
  },
  {
    id: GEAR_HALF_PLATE,
    probabilityWeight: 50,
  },
  {
    id: GEAR_RING_MAIL,
    probabilityWeight: 50,
  },
  {
    id: GEAR_CHAIN_MAIL,
    probabilityWeight: 50,
  },
  {
    id: GEAR_SPLINT_ARMOR,
    probabilityWeight: 50,
  },
  {
    id: GEAR_PLATE_ARMOR,
    probabilityWeight: 50,
  },
  {
    id: GEAR_SHIELD,
    probabilityWeight: 50,
  },
  {
    id: GEAR_QUARTERSTAFF,
    probabilityWeight: 100,
  },
  {
    id: GEAR_MACE,
    probabilityWeight: 100,
  },
  {
    id: GEAR_CLUB,
    probabilityWeight: 100,
  },
  {
    id: GEAR_DAGGER,
    probabilityWeight: 100,
  },
  {
    id: GEAR_SPEAR,
    probabilityWeight: 100,
  },
  {
    id: GEAR_LIGHT_HAMMER,
    probabilityWeight: 100,
  },
  {
    id: GEAR_JAVELIN,
    probabilityWeight: 100,
  },
  {
    id: GEAR_GREATCLUB,
    probabilityWeight: 100,
  },
  {
    id: GEAR_HANDAXE,
    probabilityWeight: 100,
  },
  {
    id: GEAR_SICKLE,
    probabilityWeight: 100,
  },
  {
    id: GEAR_YKLWA,
    probabilityWeight: 100,
  },
  {
    id: GEAR_CROSSBOW_LIGHT,
    probabilityWeight: 100,
  },
  {
    id: GEAR_DART,
    probabilityWeight: 100,
  },
  {
    id: GEAR_SHORTBOW,
    probabilityWeight: 100,
  },
  {
    id: GEAR_SLING,
    probabilityWeight: 100,
  },
  {
    id: GEAR_HALBERD,
    probabilityWeight: 100,
  },
  {
    id: GEAR_WAR_PICK,
    probabilityWeight: 100,
  },
  {
    id: GEAR_WARHAMMER,
    probabilityWeight: 100,
  },
  {
    id: GEAR_BATTLEAXE,
    probabilityWeight: 100,
  },
  {
    id: GEAR_GLAIVE,
    probabilityWeight: 100,
  },
  {
    id: GEAR_GREATSWORD,
    probabilityWeight: 100,
  },
  {
    id: GEAR_LANCE,
    probabilityWeight: 100,
  },
  {
    id: GEAR_LONGSWORD,
    probabilityWeight: 100,
  },
  {
    id: GEAR_WHIP,
    probabilityWeight: 100,
  },
  {
    id: GEAR_SHORTSWORD,
    probabilityWeight: 100,
  },
  {
    id: GEAR_MAUL,
    probabilityWeight: 100,
  },
  {
    id: GEAR_MORNINGSTAR,
    probabilityWeight: 100,
  },
  {
    id: GEAR_PIKE,
    probabilityWeight: 100,
  },
  {
    id: GEAR_RAPIER,
    probabilityWeight: 100,
  },
  {
    id: GEAR_GREATAXE,
    probabilityWeight: 100,
  },
  {
    id: GEAR_SCIMITAR,
    probabilityWeight: 100,
  },
  {
    id: GEAR_TRIDENT,
    probabilityWeight: 100,
  },
  {
    id: GEAR_FLAIL,
    probabilityWeight: 100,
  },
  {
    id: GEAR_CROSSBOW_HAND,
    probabilityWeight: 100,
  },
  {
    id: GEAR_CROSSBOW_HEAVY,
    probabilityWeight: 100,
  },
  {
    id: GEAR_LONGBOW,
    probabilityWeight: 100,
  },
  {
    id: GEAR_BLOWGUN,
    probabilityWeight: 100,
  },
  {
    id: GEAR_NET,
    probabilityWeight: 100,
  },
  {
    id: GEAR_ABACUS,
    probabilityWeight: 1,
  },
  {
    id: GEAR_BLOCK_AND_TACKLE,
    probabilityWeight: 1,
  },
  {
    id: GEAR_BARREL,
    probabilityWeight: 1,
  },
  {
    id: GEAR_WATERSKIN,
    probabilityWeight: 1,
  },
  {
    id: GEAR_BOTTLE_GLASS,
    probabilityWeight: 1,
  },
  {
    id: GEAR_BUCKET,
    probabilityWeight: 1,
  },
  {
    id: GEAR_SCALE_MERCHANTS,
    probabilityWeight: 1,
  },
  {
    id: GEAR_POT_IRON,
    probabilityWeight: 1,
  },
  {
    id: GEAR_PITCHER,
    probabilityWeight: 1,
  },
  {
    id: GEAR_LOCK,
    probabilityWeight: 1,
  },
  {
    id: GEAR_MIRROR_STEEL,
    probabilityWeight: 10,
  },
  {
    id: GEAR_MANACLES,
    probabilityWeight: 10,
  },
  {
    id: GEAR_BOOK,
    probabilityWeight: 1,
  },
  {
    id: GEAR_SPELLBOOK,
    probabilityWeight: 20,
  },
  {
    id: GEAR_BELL,
    probabilityWeight: 1,
  },
  {
    id: GEAR_QUIVER,
    probabilityWeight: 10,
  },
  {
    id: GEAR_SIGNET_RING,
    probabilityWeight: 10,
  },
  {
    id: GEAR_CLIMBER_S_KIT,
    probabilityWeight: 1,
  },
  {
    id: GEAR_FISHING_TACKLE,
    probabilityWeight: 1,
  },
  {
    id: GEAR_CASE_CROSSBOW_BOLT,
    probabilityWeight: 1,
  },
  {
    id: GEAR_CASE_MAP_OR_SCROLL,
    probabilityWeight: 1,
  },
  {
    id: GEAR_BASKET,
    probabilityWeight: 1,
  },
  {
    id: GEAR_POUCH,
    probabilityWeight: 1,
  },
  {
    id: GEAR_GRAPPLING_HOOK,
    probabilityWeight: 5,
  },
  {
    id: GEAR_JUG,
    probabilityWeight: 1,
  },
  {
    id: GEAR_LAMP,
    probabilityWeight: 10,
  },
  {
    id: GEAR_LADDER,
    probabilityWeight: 1,
  },
  {
    id: GEAR_CROWBAR,
    probabilityWeight: 10,
  },
  {
    id: GEAR_SHOVEL,
    probabilityWeight: 10,
  },
  {
    id: GEAR_WAND,
    probabilityWeight: 10,
  },
  {
    id: GEAR_ROD,
    probabilityWeight: 10,
  },
  {
    id: GEAR_CRYSTAL,
    probabilityWeight: 10,
  },
  {
    id: GEAR_STAFF,
    probabilityWeight: 10,
  },
  {
    id: GEAR_ORB,
    probabilityWeight: 10,
  },
  {
    id: GEAR_SACK,
    probabilityWeight: 1,
  },
  {
    id: GEAR_COMPONENT_POUCH,
    probabilityWeight: 1,
  },
  {
    id: GEAR_HAMMER_SLEDGE,
    probabilityWeight: 10,
  },
  {
    id: GEAR_HAMMER,
    probabilityWeight: 10,
  },
  {
    id: GEAR_CLOTHES_TRAVELERS,
    probabilityWeight: 50,
  },
  {
    id: GEAR_CLOTHES_COSTUME,
    probabilityWeight: 50,
  },
  {
    id: GEAR_CLOTHES_COMMON,
    probabilityWeight: 50,
  },
  {
    id: GEAR_CLOTHES_FINE,
    probabilityWeight: 50,
  },
  {
    id: GEAR_BLANKET,
    probabilityWeight: 1,
  },
  {
    id: GEAR_HUNTING_TRAP,
    probabilityWeight: 1,
  },
  {
    id: GEAR_TENT,
    probabilityWeight: 1,
  },
  {
    id: GEAR_HOURGLASS,
    probabilityWeight: 1,
  },
  {
    id: GEAR_SPYGLASS,
    probabilityWeight: 1,
  },
  {
    id: GEAR_BACKPACK,
    probabilityWeight: 1,
  },
  {
    id: GEAR_ROBES,
    probabilityWeight: 50,
  },
  {
    id: GEAR_AMULET,
    probabilityWeight: 10,
  },
  {
    id: GEAR_RELIQUARY,
    probabilityWeight: 10,
  },
  {
    id: GEAR_EMBLEM,
    probabilityWeight: 10,
  },
  {
    id: GEAR_SIGNAL_WHISTLE,
    probabilityWeight: 1,
  },
  {
    id: GEAR_BEDROLL,
    probabilityWeight: 1,
  },
  {
    id: GEAR_MESS_KIT,
    probabilityWeight: 1,
  },
  {
    id: GEAR_CHEST,
    probabilityWeight: 5,
  },
  {
    id: GEAR_RAM_PORTABLE,
    probabilityWeight: 5,
  },
  {
    id: GEAR_WHETSTONE,
    probabilityWeight: 1,
  },
  {
    id: GEAR_TINDERBOX,
    probabilityWeight: 1,
  },
  {
    id: GEAR_MAGNIFYING_GLASS,
    probabilityWeight: 1,
  },
  {
    id: GEAR_VIAL,
    probabilityWeight: 1,
  },
  {
    id: GEAR_INK_PEN,
    probabilityWeight: 1,
  },
  {
    id: GEAR_TANKARD,
    probabilityWeight: 1,
  },
  {
    id: GEAR_FLASK,
    probabilityWeight: 1,
  },
  {
    id: GEAR_SPRIG_OF_MISTLETOE,
    probabilityWeight: 100,
  },
  {
    id: GEAR_SPRIG_OF_HOLLY,
    probabilityWeight: 100,
  },
  {
    id: GEAR_YEW_WAND,
    probabilityWeight: 100,
  },
  {
    id: GEAR_YEW_SCEPTER,
    probabilityWeight: 100,
  },
  {
    id: GEAR_WOODEN_STAFF,
    probabilityWeight: 100,
  },
  {
    id: GEAR_TOTEM,
    probabilityWeight: 100,
  },
  {
    id: GEAR_LANTERN_HOODED,
    probabilityWeight: 10,
  },
  {
    id: GEAR_LANTERN_BULLSEYE,
    probabilityWeight: 10,
  },
  {
    id: GEAR_POLE,
    probabilityWeight: 5,
  },
  {
    id: GEAR_DRAGON_CHESS,
    probabilityWeight: 2,
  },
  {
    id: GEAR_PLAYING_CARD_SET,
    probabilityWeight: 2,
  },
  {
    id: GEAR_DICE_SET,
    probabilityWeight: 2,
  },
  {
    id: GEAR_THREE_DRAGON_ANTE,
    probabilityWeight: 2,
  },
  {
    id: GEAR_DRUM,
    probabilityWeight: 50,
  },
  {
    id: GEAR_VIOL,
    probabilityWeight: 50,
  },
  {
    id: GEAR_BAGPIPES,
    probabilityWeight: 50,
  },
  {
    id: GEAR_LYRE,
    probabilityWeight: 50,
  },
  {
    id: GEAR_LUTE,
    probabilityWeight: 50,
  },
  {
    id: GEAR_HORN,
    probabilityWeight: 50,
  },
  {
    id: GEAR_PAN_FLUTE,
    probabilityWeight: 50,
  },
  {
    id: GEAR_FLUTE,
    probabilityWeight: 50,
  },
  {
    id: GEAR_DULCIMER,
    probabilityWeight: 50,
  },
  {
    id: GEAR_SHAWM,
    probabilityWeight: 50,
  },
  {
    id: GEAR_CHARIOT,
    probabilityWeight: 5,
  },
  {
    id: GEAR_CARRIAGE,
    probabilityWeight: 5,
  },
  {
    id: GEAR_SLED,
    probabilityWeight: 5,
  },
  {
    id: GEAR_SADDLEBAGS,
    probabilityWeight: 1,
  },
  {
    id: GEAR_MILITARY_SADDLE,
    probabilityWeight: 1,
  },
  {
    id: GEAR_PACK_SADDLE,
    probabilityWeight: 1,
  },
  {
    id: GEAR_RIDING_SADDLE,
    probabilityWeight: 1,
  },
  {
    id: GEAR_EXOTIC_SADDLE,
    probabilityWeight: 1,
  },
  {
    id: GEAR_CART,
    probabilityWeight: 5,
  },
  {
    id: GEAR_BIT_AND_BRIDLE,
    probabilityWeight: 1,
  },
  {
    id: GEAR_WAGON,
    probabilityWeight: 5,
  },
  {
    id: GEAR_WARSHIP,
    probabilityWeight: 5,
  },
  {
    id: GEAR_GALLEY,
    probabilityWeight: 5,
  },
  {
    id: GEAR_KEELBOAT,
    probabilityWeight: 5,
  },
  {
    id: GEAR_LONGSHIP,
    probabilityWeight: 5,
  },
  {
    id: GEAR_SAILING_SHIP,
    probabilityWeight: 5,
  },
  {
    id: GEAR_ROWBOAT,
    probabilityWeight: 5,
  },
  {
    id: GEAR_CANOE,
    probabilityWeight: 5,
  },
  {
    id: GEAR_RAIN_CATCHER,
    probabilityWeight: 1,
  },
]
