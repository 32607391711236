export default [

  {
    id: 'Argues',
    probabilityWeight: 1,
    description: 'Сварлив',
  },
  {
    id: 'Arrogant',
    probabilityWeight: 1,
    description: 'Высокомерен',
  },
  {
    id: 'Shows-off',
    probabilityWeight: 1,
    description: 'Хвастлив',
  },
  {
    id: 'Rough',
    probabilityWeight: 1,
    description: 'Груб',
  },
  {
    id: 'Inquisitive',
    probabilityWeight: 1,
    description: 'Любознателен',
  },
  {
    id: 'Friendly',
    probabilityWeight: 1,
    description: 'Дружелюбен',
  },
  {
    id: 'Honest',
    probabilityWeight: 1,
    description: 'Честен',
  },
  {
    id: 'Quick-tempered',
    probabilityWeight: 1,
    description: 'Вспыльчив',
  },
  {
    id: 'Annoying',
    probabilityWeight: 1,
    description: 'Раздражителен',
  },
  {
    id: 'Tedious',
    probabilityWeight: 1,
    description: 'Нуден',
  },
  {
    id: 'Quiet',
    probabilityWeight: 1,
    description: 'Тих',
  },
  {
    id: 'Suspicious',
    probabilityWeight: 1,
    description: 'Подозрителен',
  },
  {
    id: 'pragmatic',
    probabilityWeight: 1,
    description: 'Прагматичен',
  },
  {
    id: 'surov',
    probabilityWeight: 1,
    description: 'Суров',
  },
  {
    id: 'obshchitelen',
    probabilityWeight: 1,
    description: 'Общителен',
  },
]
