import {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_ELEMENTAL_AIR,
  CREATURE_TYPE_CELESTIAL,
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_ELEMENTAL_EARTH,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_FEY,
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_ELEMENTAL_FIRE,
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_GNOME,
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_UNDEAD,
  CREATURE_TYPE_ELEMENTAL_WATER,
} from '@/constants/creatureTypeIdList'

const gnomeText = `Кроме того, в ★нём★ есть шестерёнки и механические части, даже если они необязательны для ★его★ функционирования.`
const dwarfText = `★Он★ ★прочен★, в ★его★ конструкции заложены руны дварфийского языка.`
const dragonText1 = `★Он★ ★сделан★ из чешуи и когтей дракона`
const dragonText2 = `★Он★ немного теплеет на расстоянии 120 футов от дракона.`
const drowText = `★Он★ весит половину стандартного веса. ★Он★ ★чёрный★, ★украшен★ пауками и паутиной в честь Ллос.`
const fientText = `Ткань и кожа заменены шкурой исчадия. ★Он★ ★тёплый★ на ощупь, а на ★его★ поверхности выгравированы рунические символы. Небожители считают ★его★ ★отталкивающим★.`
const undeadText1 = `На ★нём★ находятся образы смерти, такие как кости и черепа`
const undeadText2 = `На ощупь ★кажется★ ★холодным★.`
const feyText = `★Он★ искусно ★сделан★ из превосходнейших материалов и ★даёт★ бледное излучение в лунном свете, проливая тусклый свет в радиусе 5 футов. Вместо железа и стали используется`
const humanKingdomText = `★Он★ ★был★ ★создан★ во времена расцвета ныне павшего королевства.` // TODO: название?
const humanPersonText = `★Он★ ★связан★ с легендарной личностью.` // TODO: имя?
const humanTextsText = `На ★нём★ начертаны письмена на забытом языке.`
const humanSymbolsText = `На ★нём★ начертаны символы, чьё значение затерялось в веках.`
const earthElementalText = `Любые тканевые или кожаные элементы усыпаны хорошо отполированными камнями.`

export default [
  {
    id: CREATURE_TYPE_ABERRATION,
    probabilityWeight: 12,
    creatureTypeId: CREATURE_TYPE_ABERRATION,
    description: '★Он★ ★был★ ★создан★ аберрациями в древние времена, возможно, для использования избранными гуманоидными рабами. Если смотреть на ★Предмет★ боковым зрением, кажется, что ★он★ ★шевелится★.',
  },
  {
    id: CREATURE_TYPE_GIANT,
    probabilityWeight: 12,
    creatureTypeId: CREATURE_TYPE_GIANT,
    description: '★Он★ больше обычного размера и ★был★ ★сделан★ великанами для их меньших союзников.',
  },
  {
    id: 'gnome_old',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_GNOME,
    description: `★Он★ ★выглядит★ ★поношенным★. ${gnomeText}`,
  },
  {
    id: 'gnome_new',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_GNOME,
    description: `★Он★ ★выглядит★ ★новым★. ${gnomeText}`,
  },
  {
    id: 'dwarf_free',
    probabilityWeight: 12,
    creatureTypeId: CREATURE_TYPE_DWARF,
    description: dwarfText,
  },
  {
    id: 'dwarf_clan',
    probabilityWeight: 12,
    creatureTypeId: CREATURE_TYPE_DWARF,
    description: `${dwarfText} ★Он★ принадлежит какому-либо клану, который ждёт возвращение этого предмета.`, // TODO: какому?
  },
  {
    id: 'dragon_no_gems',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_DRAGON,
    description: `${dragonText1}. ${dragonText2}`,
  },
  {
    id: 'dragon_gems',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_DRAGON,
    description: `${dragonText1}, с вкраплениями драгоценных металлов и камней из драконьих запасов. ${dragonText2}`,
  },
  {
    id: 'drow_sunny',
    probabilityWeight: 4,
    creatureTypeId: CREATURE_TYPE_DROW,
    description: drowText,
  },
  {
    id: 'drow_broken',
    probabilityWeight: 4,
    creatureTypeId: CREATURE_TYPE_DROW,
    description: `${drowText} После нахождения на солнечном свете минуту или более, ★Предмет★ ★начнёт★ работать неправильно.`,
  },
  {
    id: 'drow_destroy',
    probabilityWeight: 4,
    creatureTypeId: CREATURE_TYPE_DROW,
    description: `${drowText} После нахождения на солнечном свете минуту или более ★этот★ ★Предмет★ ★распадётся★.`,
  },
  {
    id: 'fiend_steel',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_FIEND,
    description: `★Он★ ★выполнен★ из чёрной стали, с начертанными на ней рунами. ${fientText}`,
  },
  {
    id: 'fiend_horn',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_FIEND,
    description: `★Он★ ★выполнен★ из рога, с начертанными на нём рунами. ${fientText}`,
  },
  {
    id: CREATURE_TYPE_CELESTIAL,
    probabilityWeight: 12,
    creatureTypeId: CREATURE_TYPE_CELESTIAL,
    description: '★Он★ ★весит★ половину стандартного веса, ★украшен★ пернатыми крыльями, солнцем и другими символами добра. Исчадия считают ★его★ ★отталкивающим★.',
  },
  {
    id: 'undead_common',
    probabilityWeight: 4,
    creatureTypeId: CREATURE_TYPE_UNDEAD,
    description: `${undeadText1}. ${undeadText2}`,
  },
  {
    id: 'undead_bones',
    probabilityWeight: 4,
    creatureTypeId: CREATURE_TYPE_UNDEAD,
    description: `${undeadText1}, ★он★ ★сделан★ из костей. ${undeadText2}`,
  },
  {
    id: 'undead_flesh',
    probabilityWeight: 4,
    creatureTypeId: CREATURE_TYPE_UNDEAD,
    description: `${undeadText1}, ★он★ ★сделан★ из частей трупов. ${undeadText2}`,
  },
  {
    id: 'fey_silver',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_FEY,
    description: `${feyText} серебро.`,
  },
  {
    id: 'fey_mithril',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_FEY,
    description: `${feyText} мифрил.`,
  },
  {
    id: 'human_kingdom_empty',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_HUMAN,
    description: humanKingdomText,
  },
  {
    id: 'human_kingdom_texts',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_HUMAN,
    description: `${humanKingdomText} ${humanTextsText}`,
  },
  {
    id: 'human_kingdom_symbols',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_HUMAN,
    description: `${humanKingdomText} ${humanSymbolsText}`,
  },
  {
    id: 'human_person_empty',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_HUMAN,
    description: humanPersonText,
  },
  {
    id: 'human_person_texts',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_HUMAN,
    description: `${humanPersonText} ${humanTextsText}`,
  },
  {
    id: 'human_person_symbols',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_HUMAN,
    description: `${humanPersonText} ${humanSymbolsText}`,
  },
  {
    id: CREATURE_TYPE_ELEMENTAL_WATER,
    probabilityWeight: 12,
    creatureTypeId: CREATURE_TYPE_ELEMENTAL_WATER,
    description: 'Блестящая рыбья чешуя — вместо кожи и ткани. Вместо металла используются морские раковины и кораллы, столь же твёрдые и прочные, как сама сталь.',
  },
  {
    id: CREATURE_TYPE_ELEMENTAL_AIR,
    probabilityWeight: 12,
    creatureTypeId: CREATURE_TYPE_ELEMENTAL_AIR,
    description: '★Он★ ★весит★ половину стандартного веса и ★кажется★ ★полым★. Если ★он★ ★сделан★ из ткани, то ★прозрачен★.',
  },
  {
    id: 'earth_elemental_common',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_ELEMENTAL_EARTH,
    description: earthElementalText,
  },
  {
    id: 'earth_elemental_stone',
    probabilityWeight: 6,
    creatureTypeId: CREATURE_TYPE_ELEMENTAL_EARTH,
    description: `★Он★ ★сделан★ из камня. ${earthElementalText}`,
  },
  {
    id: CREATURE_TYPE_ELEMENTAL_FIRE,
    probabilityWeight: 12,
    creatureTypeId: CREATURE_TYPE_ELEMENTAL_FIRE,
    description: '★Он★ ★тёплый★ на ощупь и некоторые из ★его★ частей сделаны из чёрной стали. На ★его★ поверхности изображены языки пламени. В оформлении использованы оттенки красного и оранжевого.',
  },
  {
    id: CREATURE_TYPE_ELF,
    probabilityWeight: 24,
    creatureTypeId: CREATURE_TYPE_ELF,
    description: '★Он★ ★весит★ половину стандартного веса и ★украшен★ символами природы: листьями, лозами и звёздами.',
  },
]
