import * as creatureTypeIdCollection from '@/constants/creatureTypeIdList'

const creatureTypeIdList = Object.values(creatureTypeIdCollection)

export default creatureTypeIdList.map(
  id => ({
    id,
    probabilityWeight: 1,
  })
)
