import React from 'react'

import { SOURCE_DMG } from '@/constants/sourceList'

import PageTitle from '@/components/PageTitle'
import BtnGenerate from '@/components/BtnGenerate'
import Description from '@/components/Description'

import SentientItem from './components/SentientItem'

import './SentientItemGeneratorStyles.less'

const SentientItemGeneratorComponent = ({ item, onGenerate }) => (
  <section className="SentientItemGenerator">
    <PageTitle>Генератор разумных магических предметов</PageTitle>
    <Description
      text={`Некоторые магические предметы обладают разумом и чертами характера. Такой предмет может быть одержимым духом прежнего владельца, или же может обладать самосознанием благодаря сотворившей его магии. В любом случае, такой предмет ведёт себя как персонаж, со своими причудами, идеалами, привязанностями, а иногда и недостатками. Разумный предмет может быть ценным союзником, либо же постоянным раздражителем

Большая часть разумных предметов это оружие. Разумными могут быть и другие предметы, но такие расходуемые предметы как зелья и свитки никогда не бывают разумными.

Разумные магические предметы действуют как НИП под управлением Мастера. Все активированные свойства предмета находятся под его собственным управлением, а не под управлением владельца. Пока владелец поддерживает хорошие отношения с предметом, он имеет доступ к этим свойствам. Если отношения напряжённые, предмет может подавить активированные свойства или даже обратить их против владельца.`}
      source={{
        id: SOURCE_DMG,
        page: 215,
      }}
    />
    <BtnGenerate
      className="SentientItemGenerator_btn"
      onClick={onGenerate}
    />
    {
      item
        ? <SentientItem item={item} />
        : null
    }
  </section>
)

export default SentientItemGeneratorComponent
