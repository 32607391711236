import { creatureTypeCollection } from '@/constants/creatureTypeList'
import { pcClassCollection } from '@/constants/pcClassList'

import kindList from './../constants/kindList'

import pickByPropability from '@/utils/pickByPropability'

export default () => {
  const { id, type } = pickByPropability(kindList)

  switch (type) {
    case 'pcClass': {
      return  pcClassCollection[id].name
    }

    default:
    case 'creatureType': {
      return  creatureTypeCollection[id].name
    }
  }
}
