import {aligmentCollection} from "@/constants/aligmentList"

const checkIfAligmentPossible = (checkAligmentId, goalAligmentId, excludeIdList = []) => {
  excludeIdList.push(goalAligmentId)

  if (checkAligmentId === goalAligmentId) {
    return true
  }

  const aligment = aligmentCollection[goalAligmentId]

  if (aligment) {
    const {children} = aligment

    if (children && Array.isArray(children)) {
      if (children.includes(checkAligmentId)) {
        return true
      }

      return children
        .filter(
          id => !excludeIdList.includes(id)
        )
        .some(id => {
          return checkIfAligmentPossible(checkAligmentId, id, excludeIdList)
        })
    }
  }

  return false
}

export default checkIfAligmentPossible
