import {
  ALIGNMENT_LG,
  ALIGNMENT_NG,
  ALIGNMENT_CG,
  ALIGNMENT_LN,
  ALIGNMENT_N,
  ALIGNMENT_CN,
  ALIGNMENT_LE,
  ALIGNMENT_NE,
  ALIGNMENT_CE,
} from '@/constants/aligmentList'

export default [
  {
    id: ALIGNMENT_LG,
    probabilityWeight: 15,
  },
  {
    id: ALIGNMENT_NG,
    probabilityWeight: 20,
  },
  {
    id: ALIGNMENT_CG,
    probabilityWeight: 15,
  },
  {
    id: ALIGNMENT_LN,
    probabilityWeight: 13,
  },
  {
    id: ALIGNMENT_N,
    probabilityWeight: 10,
  },
  {
    id: ALIGNMENT_CN,
    probabilityWeight: 12,
  },
  {
    id: ALIGNMENT_LE,
    probabilityWeight: 4,
  },
  {
    id: ALIGNMENT_NE,
    probabilityWeight: 7,
  },
  {
    id: ALIGNMENT_CE,
    probabilityWeight: 4,
  },
]
