export default [
  {
    id: 'Ispolnennyi-blagosti',
    probabilityWeight: 1,
    name: 'Исполненный благости',
    description: 'Пока ★Предмет★ находится у владельца, он чувствует себя счастливым и в будущее своё смотрит с оптимизмом. Бабочки и другие милые, безобидные создания порхают и резвятся в присутствии этого предмета',
  },
  {
    id: 'Samouverennyi',
    probabilityWeight: 1,
    name: 'Самоуверенный',
    description: '★Предмет★ даёт владельцу чувство уверенности.',
  },
  {
    id: 'Skupoi',
    probabilityWeight: 1,
    name: 'Скупой',
    description: 'Владелец становится одержим жаждой материального богатства.',
  },
  {
    id: 'KHrupkii',
    probabilityWeight: 1,
    name: 'Хрупкий',
    description: '★Предмет★ скрипит, трещит и крошится, когда Вы ★его★ используете, носите на себе или активируете. Это никоим образом не сказывается на ★его★ свойствах, но, если ★Предметом★ пользовались продолжительное время, то вид у ★него★ потрёпанный.',
  },
  {
    id: 'Golodnyi',
    probabilityWeight: 1,
    name: 'Голодный',
    description: 'Магические свойства этого предмета работают только в том случае, если в последние 24 часа ★Предмет★ ★имел★ контакт с кровью гуманоида. Совсем немного, капли достаточно.',
  },
  {
    id: 'Shumnyi',
    probabilityWeight: 1,
    name: 'Шумный',
    description: 'При использовании ★Предмет★ производит всякого рода громкие звуки: лязг, крик, звуки удара по гонгу.',
  },
  {
    id: 'Metamorfozy',
    probabilityWeight: 1,
    name: 'Метаморфозы',
    description: 'Периодически ★Предмет★ случайным образом несколько ★изменяет★ свой внешний вид. На возможности предмета подобные метаморфозы никак не влияют.',
  },
  {
    id: 'Vorchlivyi',
    probabilityWeight: 1,
    name: 'Ворчливый',
    description: '★Предмет★ еле слышно ★бормочет★ что-то и ★ворчит★. Существо, которое прислушается к этому брюзжанию, может узнать что-нибудь полезное.',
  },
  {
    id: 'Boleznennyi',
    probabilityWeight: 1,
    name: 'Болезненный',
    description: 'При использовании владелец этого предмета чувствует не очень приятные, но, однако же, безобидные вспышки боли.',
  },
  {
    id: 'Sobstvennik',
    probabilityWeight: 1,
    name: 'Собственник',
    description: '★Предмет★ ★требует★ настройки при первом использовании или надевании, и не ★позволяет★ владельцу настраиваться на другие предметы (те предметы, на которые владелец был настроен, сохраняют свою связь с ним, пока она не закончится каким-либо образом).',
  },
  {
    id: 'Ottalkivaiushchii',
    probabilityWeight: 1,
    name: 'Отталкивающий',
    description: 'Владелец испытывает отвращение, прикасаясь к этому предмету, и продолжает испытывать дискомфорт, пока носит его.',
  },
  {
    id: 'Privlekatelnyi',
    probabilityWeight: 1,
    name: 'Привлекательный',
    description: 'Владелец испытывает небольшое возбуждение, когда прикасается к этому предмету и продолжает испытывать разнообразные приятные ощущения, пока носит его.',
  },
  {
    id: 'Lenivyi',
    probabilityWeight: 1,
    name: 'Ленивый',
    description: 'Владелец этого предмета чувствует себя вялым и апатичным. Пока он настроен на ★Предмет★, ему требуется **10** часов для завершения длинного отдыха.',
  },
  {
    id: 'Trudiaga',
    probabilityWeight: 1,
    name: 'Трудяга',
    description: 'Владелец этого предмета чувствует себя бодрым и энергичным. Пока он настроен на ★Предмет★, ему требуется **6** часов для завершения длинного отдыха.',
  },
]
