export default [
  {
    id: 'educated',
    probabilityWeight: 1,
    description: '★образованный★',
  },
  {
    id: 'umnyi',
    probabilityWeight: 1,
    description: '★умный★',
  },
  {
    id: 'curious',
    probabilityWeight: 1,
    description: '★любознательный★',
  },
  {
    id: 'insightful',
    probabilityWeight: 1,
    description: '★проницательный★',
  },
  {
    id: 'attentive',
    probabilityWeight: 1,
    description: '★внимательный★',
  },
  {
    id: 'convincing',
    probabilityWeight: 1,
    description: '★убедительный★',
  },
  {
    id: 'volitional',
    probabilityWeight: 1,
    description: '★волевой★',
  },
  {
    id: 'brave',
    probabilityWeight: 1,
    description: '★смелый★',
  },
  {
    id: 'self-uveren',
    probabilityWeight: 1,
    description: '★самоуверенный★',
  },
  {
    id: 'otvajen',
    probabilityWeight: 1,
    description: '★отважный★',
  },
  {
    id: 'predusmotritelen',
    probabilityWeight: 1,
    description: '★предусмотрительный★',
  },
  {
    id: 'vesyolyi',
    probabilityWeight: 1,
    description: '★весёлый★',
  },
  {
    id: 'vezhlivyi',
    probabilityWeight: 1,
    description: '★вежливый★',
  },
]
